<template>
    <div>
<!--        <div class="d-flex justify-content-between mt-2" :class="{'page-break-inside-pdf': printType == 'pdf'}">-->
        <div class="d-flex justify-content-between mt-2 page-break-inside-pdf">
        <div style="width: 65%;">
          <div class="total-border d-flex justify-content-between" v-if="inContentTemp('invoice_subtotal')">
            <div class="padding-5-20">
              <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Before VAT</span></h3>
            </div>
            <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ formatNumber(data.invoice_total_before_taxes) }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>
          <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_discount')">
            <div class="padding-5-20">
              <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الخصومات</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Discount</span></h3>
            </div>
            <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.invoice_total_discount }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>
          <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('shipping_fees')">
            <div class="padding-5-20">
              <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الشحن</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Shipping</span></h3>
            </div>
            <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.shipping_fees }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>
          <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_taxes')">
            <div class="padding-5-20">
              <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>ضريبة القيمة المضافة 15%</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">VAT 15%</span></h3>
            </div>
            <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ data.invoice_total_taxes }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>
          <div class="total-border d-flex mt-1 justify-content-between" v-if="inContentTemp('invoice_total_after_taxes')">
            <div class="padding-5-20">
              <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع مع الضريبة 15%</span> <span v-if="is_multi_language_label" style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total with VAT 15%</span></h3>
            </div>
            <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b class="f-s-15px">{{ formatNumber(data.invoice_total) }}</b></span> <span class="color-1a5a4a">{{ data.currency_name }}</span></div>
          </div>

          <div class="mt-1 d-flex" v-if="is_payment_details && data.payments && data.payments.length">
            <div class="w-60per">
              <div class="green-cc-3" style="padding: 2px 5px;height: 100%;">
                <h5 class="color-1a5a4a f-s-15px mb-2 d-flex justify-content-between"><b>المدفوعات</b> <b v-if="is_multi_language_label">Payments</b></h5>
                <div>
                  <h6 style="display: inline-block;" v-for="(row, index) in data.payments" v-if="row.amount != 0 && row.amount != null" :key="index"> {{ row.payment_method }} {{ formatNumber(row.amount) }} {{ row.currency_name }} </h6>

                </div>
              </div>
            </div>
          </div>
          <div class="text-center total-border mt-1"  v-if="inContentTemp('word_invoice_total') || inContentTemp('word_invoice_total_en')" style="padding: 2px 5px;border-radius: 10px;">
            <p class="d-flex justify-content-between mb-2"><span>المبلغ بالكلمات</span> <span v-if="is_multi_language_label" style="margin: 0 0.5rem">Amount in words</span></p>
            <p class="d-flex justify-content-between mb-0">
              <span v-if="data.word_invoice_total && inContentTemp('word_invoice_total')">{{data.word_invoice_total}}</span>
              <span v-if="data.word_invoice_total_en && inContentTemp('word_invoice_total_en')">{{data.word_invoice_total_en}}</span>
            </p>
<!--/*            <p v-if="data.word_invoice_total_en && inContentTemp('word_invoice_total_en')" style="margin-bottom: 0;text-align: left;">{{data.word_invoice_total_en}}</p>*/-->
          </div>


          <!--          <div class="mt-3 d-flex" v-if="is_payment_details && data.payments && data.payments.length">-->
<!--            <div class="w-60per">-->
<!--              <div class="green-cc-3" style="padding: 2px 5px;">-->
<!--                <h5 class="color-1a5a4a f-s-22px mb-4"><b>المدفوعات</b></h5>-->
<!--                <h5 class="color-1a5a4a f-s-22px mb-4" v-if="is_multi_language_label"><b>Payments</b></h5>-->
<!--                <div>-->
<!--                  <h6 style="display: inline-block;" v-for="(row, index) in data.payments" :key="index"> {{ row.payment_method }} {{ formatNumber(row.amount) }} {{ row.currency_name }} </h6>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--        </div>-->
        

        </div>

          <div v-if="data.status != 1 && printType == 'print'" class="text-center total-border" style="padding: 5px 4px;width: 261px; height: fit-content;">
            <!--            <div class="qr-border text-center" style="margin: 10px auto 3px auto; width: 330px;">-->
            <qr-code v-if="data.data_qr_code" :text="data.data_qr_code" :size="size" class="text-center"></qr-code>
            <!--            </div>-->
          </div>


        </div>
<!--      <div v-if="printType == 'print'" class="d-flex justify-content-between mt-2"  :class="{'page-break-inside-pdf': printType == 'pdf'}">-->
<!--      <template v-if="printType == 'print'">-->
<!--        <div class="d-flex justify-content-between mt-2 page-break-inside-pdf">-->
<!--          <div v-if="data.status != 1" class="text-center total-border h-auto" style="padding-right: 24px;padding-top: 11px;width: 305px;">-->
<!--&lt;!&ndash;            <div class="qr-border text-center" style="margin: 10px auto 3px auto; width: 330px;">&ndash;&gt;-->
<!--              <qr-code v-if="data.data_qr_code" :text="data.data_qr_code" :size="size" class="text-center"></qr-code>-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div v-if="is_banking_details" class="total-border p-2 h-100" style="margin-right: 0.25rem;width: 440px;">-->
<!--            <p class="d-flex justify-content-between"><span>التفاصيل المصرفية</span> <span v-if="is_multi_language_label" style="margin: 0 0.5rem">Bank Details</span></p>-->
<!--            <div class="acc-bank-border">-->
<!--              <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>اسم الحساب</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">Account Name</span></p>-->
<!--              <p class="mb-0 f-s-15px">{{ data.company ? data.company.account_holder_name : '' }}</p>-->
<!--            </div>-->
<!--            <div class="d-flex justify-content-between acc-bank-border">-->
<!--              <div>-->
<!--                <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span style="margin-left: 5px;margin-right: 5px;"> البنك </span>   <span v-if="is_multi_language_label"> Bank </span></p>-->
<!--                <p class="mb-0 f-s-15px">{{ data.company ? data.company.bank_name : '' }}</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span style="margin-left: 5px;margin-right: 5px;"> الفرع </span>   <span  v-if="is_multi_language_label"> Branch </span></p>-->
<!--                <p class="mb-0 f-s-15px">{{ data.company ? data.company.bank_branch : '' }}</p>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="acc-bank-border">-->
<!--              <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>الحساب</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">Account</span></p>-->
<!--              <p class="mb-0 f-s-15px">{{ data.company ? data.company.account_holder_number : '' }}</p>-->
<!--            </div>-->
<!--            <div class="acc-bank-border">-->
<!--              <p class="color-1a5a4a mb-0 d-flex justify-content-between f-s-15px"><span>الايبان</span>   <span style="display: block; direction: ltr;" v-if="is_multi_language_label">IBAN</span></p>-->
<!--              <p class="mb-0 f-s-15px">{{ data.company ? data.company.iban : '' }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="isSignature" class="d-flex justify-content-between mt-5 page-break-inside-pdf" style="padding-bottom: 80px;">-->
<!--          <div class="h-100">-->
<!--            <p class="m-0 f-s-15px text-center">Received By</p>-->
<!--            <div>-->
<!--              <p class="m-0 f-s-15px text-center">التوقيع مع الختم (Signed with stamp)</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="h-100">-->
<!--            <p class="m-0 f-s-15px text-center">Authorized Signatory</p>-->
<!--            <div>-->
<!--              <p class="m-0 f-s-15px text-center">التوقيع مع الختم (Signed with stamp)</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </template>-->
      <main-footer  :inner-data="data" :data-footer="{is_multi_language_label: isMultiLanguageLabel, is_banking_details: isBankingDetails, is_signature: isSignature}"></main-footer>


      <!--      <div v-if="data.pos_policy">-->
<!--        <p v-if="is_break_before_footer"><span :style="'border: 1px solid;width: 70%;height: 0px;display: block;margin: 20px auto 0;border-color:'+footer_font_color"></span></p>-->
<!--        <p>{{data.pos_policy}}</p>-->
<!--      </div>-->

    </div>
</template>

<script>
    import VueQRCodeComponent from 'vue-qrcode-component';
    import MainFooter from "@/view/content/printing-footers/MainFooter.vue";
    export default {
        name: 'TaxInvoiceLines',
        components:{
          MainFooter,
            'qr-code':VueQRCodeComponent
        },
        props:{
            data:{
                type:Object,
                default:()=>({})
            },
          inContentTemp:{type:Function},
          isMultiLanguageLabel:{type:Boolean},
          isBreakBeforeFooter:{type:Boolean},
          isPaymentDetails:{type:Boolean},
          isBankingDetails:{type:Boolean},
          footerFontColor:{type:String},
          isSignature:{type:Boolean},
          printType:{type:String},

        },
      data(){
          return{
            is_multi_language_label: false,
            is_break_before_footer: false,
            is_payment_details: false,
            is_banking_details: false,
            is_signature: false,
            footer_font_color: '#000',
            size: 250,
          }
      },
        methods:{
            formatNumber(number) {
                // Format the number with commas if it's greater than 5 digits
                return number > 9999 ? new Intl.NumberFormat().format(number) : number;
            },

        },
      created(){
        this.is_multi_language_label = this.isMultiLanguageLabel ? this.isMultiLanguageLabel : false;
        this.is_break_before_footer = this.isBreakBeforeFooter ? this.isBreakBeforeFooter : false;
        this.is_payment_details = this.isPaymentDetails ? this.isPaymentDetails : false;
        this.is_banking_details = this.isBankingDetails ? this.isBankingDetails : false;
        this.is_signature = this.isSignature ? this.isSignature : false;
        this.footer_font_color = this.footerFontColor ? this.footerFontColor : '#000';
      },
    }
</script>

<style lang="scss" scoped>

</style>